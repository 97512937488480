// src/components/Login.js
import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import * as jwt_decode from 'jwt-decode';



const Login = ({ setUser }) => {
  const handleSuccess = (credentialResponse) => {
    const decoded = jwt_decode(credentialResponse.credential);
      setUser({
        name: decoded.name,
        email: decoded.email,
        picture: decoded.picture,
      });
  };

  const handleError = () => {
    alert('Falha na autenticação.');
  };

  return (
    <div style={styles.container}>
      <h2>Login com Google</h2>
      <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f0f0f0',
  },
};

export default Login;
