import React, { useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Login from './components/login';
import Map from './components/map';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const [user, setUser] = useState(null);

  return (
    <div>
      <Routes>
        <Route 
          path="/" 
          element={
            user ? <Navigate to="/map" /> : <Login setUser={setUser} />
          } 
        />
        <Route 
          path="/map" 
          element={
            <ProtectedRoute user={user}>
              <Map />
            </ProtectedRoute>
          } 
        />
      </Routes>
    </div>
  );
}

export default App;
